<template>
    <div
        data-scroll
        :data-scroll-id="name"
        :class="{ dark: lightMode }"
        class="today-container"
    >
        <div class="today-content">
            <div class="wipe-container">
                <text-wipe
                    id="today-wipe"
                    :color="shaderColor"
                    :header="header"
                />
            </div>
            <div v-observer class="image-container first">
                <div class="image-scale-container">
                    <mouse-changer
                        v-if="item1.video"
                        :type="mouseTypes.VideoScale"
                    >
                        <video
                            muted
                            data-scroll
                            data-scroll-id="inside-scale1"
                            autoplay
                            @click="showVideo(item1.full)"
                            loop
                            playsinline
                            :poster="item1.image"
                        >
                            <source :src="item1.video" type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>
                        <div v-if="mobile" class="play-container">
                            <svg
                                class="play-icon"
                                width="40.759"
                                height="22.708"
                                viewBox="0 0 40.759 22.708"
                            >
                                <g id="Group_55" data-name="Group 55">
                                    <line
                                        id="Line_3"
                                        data-name="Line 3"
                                        x2="19.005"
                                        y2="19.881"
                                        transform="translate(18.926 0)"
                                        fill="none"
                                        stroke="#fff"
                                        stroke-linecap="round"
                                        stroke-width="2"
                                    />
                                    <line
                                        id="Line_4"
                                        data-name="Line 4"
                                        x1="18.927"
                                        y2="19.881"
                                        transform="translate(0 0)"
                                        fill="none"
                                        stroke="#fff"
                                        stroke-linecap="round"
                                        stroke-width="2"
                                    />
                                    <line
                                        id="Line_5"
                                        data-name="Line 5"
                                        x2="38"
                                        transform="translate(0.027 19.881)"
                                        fill="none"
                                        stroke="#fff"
                                        stroke-linecap="round"
                                        stroke-width="2"
                                    />
                                </g>
                            </svg>
                        </div>
                    </mouse-changer>
                    <img
                        v-if="!item1.video"
                        :src="item1.image"
                        data-scroll
                        data-scroll-id="inside-scale1"
                        class="image"
                        alt=""
                    />
                </div>
                <div
                    v-if="item1.description"
                    class="content"
                    v-html="item1.description"
                ></div>
            </div>
            <div
                v-if="item2.image || item2.video"
                v-observer
                class="image-container second"
            >
                <div class="image-scale-container">
                    <mouse-changer v-if="item2.video">
                        <video
                            muted
                            data-scroll
                            data-scroll-id="inside-scale1"
                            autoplay
                            @click="showVideo(item2.full)"
                            loop
                            playsinline
                            :poster="item2.image"
                        >
                            <source :src="item2.video" type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>
                        <div v-if="mobile" class="play-container">
                            <svg
                                class="play-icon"
                                width="40.759"
                                height="22.708"
                                viewBox="0 0 40.759 22.708"
                            >
                                <g id="Group_55" data-name="Group 55">
                                    <line
                                        id="Line_3"
                                        data-name="Line 3"
                                        x2="19.005"
                                        y2="19.881"
                                        transform="translate(18.926 0)"
                                        fill="none"
                                        stroke="#fff"
                                        stroke-linecap="round"
                                        stroke-width="2"
                                    />
                                    <line
                                        id="Line_4"
                                        data-name="Line 4"
                                        x1="18.927"
                                        y2="19.881"
                                        transform="translate(0 0)"
                                        fill="none"
                                        stroke="#fff"
                                        stroke-linecap="round"
                                        stroke-width="2"
                                    />
                                    <line
                                        id="Line_5"
                                        data-name="Line 5"
                                        x2="38"
                                        transform="translate(0.027 19.881)"
                                        fill="none"
                                        stroke="#fff"
                                        stroke-linecap="round"
                                        stroke-width="2"
                                    />
                                </g>
                            </svg>
                        </div>
                    </mouse-changer>
                    <img
                        v-if="!item2.video"
                        :src="item2.image"
                        data-scroll
                        data-scroll-id="inside-scale2"
                        class="image"
                        alt=""
                    />
                </div>
                <div
                    class="content"
                    v-if="item2.description"
                    v-html="item2.description"
                ></div>
                <div class="content"></div>
            </div>
        </div>
    </div>
</template>

<script>
import textWipe from "@/components/custom/textWipe";
import { mapActions, mapGetters } from "vuex";
import MouseChanger from "@/components/custom/MouseChanger";

export default {
    props: {
        name: {
            type: String,
            default: ""
        },
        data: {
            type: Object,
            default: () => {}
        },
        lightMode: {
            type: Boolean,
            default: false
        },
        config: {
            type: Object,
            default: () => {}
        }
    },
    computed: {
        ...mapGetters({
            device: "resize/geDevice"
        }),
        shaderColor() {
            return this.lightMode ? [1, 1, 1] : [0.039, 0.078, 0.11];
        },
        header() {
            return this.config.header || {};
        },
        item1() {
            let item = this.data?.list?.[0];
            var data;
            var fullVideo;
            if (window.innerWidth < 1024) {
                fullVideo = item?.mobile_video?.videoFile?.url;
                data = item?.mobile_video_intro?.videoFile?.url;
            } else {
                fullVideo = item?.video?.videoFile?.url;
                data = item?.video_intro?.videoFile?.url;
            }
            //J
            return {
                image: item?.image1?.[0]?.devices?.[this.device?.type],
                video: data,
                full: fullVideo,
                description: item?.description1
            };
        },
        item2() {
            let item = this.data?.list?.[1];
            var data;
            var fullVideo;
            if (window.innerWidth < 1024) {
                fullVideo = item?.mobile_video?.videoFile?.url;
                data = item?.mobile_video_intro?.videoFile?.url;
            } else {
                fullVideo = item?.video?.videoFile?.url;
                data = item?.video_intro?.videoFile?.url;
            }

            return {
                image: item?.image1?.[0]?.devices?.[this.device?.type],
                video: data,
                full: fullVideo,
                description: item?.description1
            };
        },
        mobile() {
            return this.device?.size < 1024;
        }
    },
    components: {
        textWipe,
        MouseChanger
    },
    methods: {
        ...mapActions({
            setContent: "video/setContent"
        }),
        showVideo(path) {
            this.setContent({
                path
            });
        },
        mobileParallax() {
            if (this.device.size < 1024) {
                let images = document.querySelectorAll(
                    ".today-container .image"
                );

                new this.simpleParallax(images, {
                    customContainer: document.querySelector("body"),
                    scale: 1.5,
                    orientation: "down",
                    overflow: true
                });
            }
        }
    },
    created() {
        this.$nextTick(() => {
            this.mobileParallax();
        });
    }
};
</script>

<style lang="scss" scoped>
$ease-out: cubic-bezier(0.15, 0.16, 0.37, 1);

.today-container /deep/ {
    video {
        cursor: pointer;
    }
    padding-top: 144px;

    @media only screen and (max-width: 1365px) and (min-width: 768px) {
        padding-top: 89px;
    }
    @media only screen and (max-width: 767px) {
        padding-top: 55px;
    }

    .today-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .wipe-container /deep/ {
        position: relative;

        .header {
            color: white;
            text-align: center;
        }
    }

    .play-container {
        width: 80px;
        height: 80px;
        background: transparent;
        border-radius: 50%;
        border: 2px solid #e5b676;
        opacity: 0.9;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        @media (max-width: 1023px) {
            display: none;
        }

        .play-icon {
            transform: rotate(90deg);
        }
    }

    .image-container {
        width: calc(100% - 390px);
        margin-top: 81px;
        overflow: hidden;
        @media only screen and (max-width: 1650px) and (min-width: 1440px) {
            width: calc(100% - 330px);
            margin-top: 55px;
        }
        @media only screen and (max-width: 1439px) and (min-width: 1366px) {
            width: calc(100% - 256px);
            margin-top: 55px;
        }
        @media only screen and (max-width: 1365px) and (min-width: 768px) {
            width: calc(100% - 94px);
            margin-top: 55px;
        }
        @media only screen and (max-width: 767px) {
            width: calc(100% - 32px);
            margin-top: 34px;
        }

        &.observed {
            //     &.first {
            //         .image-scale-container {
            //             clip-path: inset(0px 0px 0px 0px);
            //         }
            //     }

            //     &.second {
            //         .image-scale-container {
            //             clip-path: inset(0px 0px 0px 0px);
            //         }
            //     }
            // }

            // &.first {
            //     .image-scale-container {
            //         clip-path: inset(0px 100% 0px 0px);
            //     }
        }

        &.second {
            margin-top: 75px;
            @media only screen and (max-width: 1650px) and (min-width: 768px) {
                margin-top: 56px;
            }
            @media only screen and (max-width: 767px) {
                margin-top: 34px;
            }

            // .image-scale-container {
            //     clip-path: inset(0px 100% 0px 100%);
            // }
        }

        .image {
            width: 100%;
            position: relative;
        }

        .image-scale-container {
            width: 100%;
            overflow: hidden;
            max-height: 843px;
            transition: clip-path 0.8s 0.25s $ease-out;
            @media only screen and (max-width: 1650px) and (min-width: 1366px) {
                max-height: 534px;
            }
            @media only screen and (max-width: 1365px) and (min-width: 1024px) {
                max-height: 449px;
            }

            @media only screen and (max-width: 1023px) and (min-width: 768px) {
                position: relative;
                height: 465px;
            }

            @media only screen and (max-width: 767px) {
                position: relative;
                height: unset !important;
            }

            .image {
                @media only screen and (max-width: 1023px) {
                    height: 100%;
                    width: 100%;
                    position: absolute;
                    object-fit: cover;
                    top: 0;
                    bottom: 0;
                    right: 0;
                    left: 0;
                    // transform: scale(0);
                }
            }

            video {
                position: relative;
                z-index: 2;
                width: 100%;
                height: 100%;
            }
        }

        &.second {
            .image-scale-container {
                @media only screen and (max-width: 1023px) {
                    position: relative;
                    height: 465px;
                    width: 100%;
                    margin: 0 auto;
                }
                @media only screen and (max-width: 767px) {
                    position: relative;
                    height: 395px;
                    max-width: 100%;
                }

                .image {
                    @media only screen and (max-width: 1023px) {
                        height: 100%;
                        width: 100%;
                        position: absolute;
                        object-fit: cover;
                        top: 0;
                        bottom: 0;
                        right: 0;
                        left: 0;
                    }
                }
            }
        }

        .content {
            width: fit-content;
            margin: 48px auto 0;
            display: flex;
            flex-direction: column;
            grid-gap: 20px;
            text-align: left;
            color: #e5b676;
            font-size: 21px;
            padding: 0 330px;
            @media only screen and (max-width: 1650px) and (min-width: 1366px) {
                padding: 0 190px;
            }
            @media only screen and (max-width: 1365px) and (min-width: 1023px) {
                padding: 0 160px;
            }
            @media only screen and (max-width: 1023px) and (min-width: 768px) {
                padding: 0 0 0 22px;
            }
            @media only screen and (max-width: 767px) {
                font-size: 16px;
                padding: 0 0 0 24.5px;
            }

            li,
            p,
            span,
            h2,
            h1,
            div {
                font-family: "Noto-Sans", "Noto-Sans-georgian" !important;
            }

            li {
                margin-top: 15px;

                &::marker {
                    font-size: 24px;
                }

                &:first-child {
                    margin-top: 0;
                }
            }
        }
    }
}

.today-container.dark /deep/ {
    .wipe-container {
        .header {
            color: #0a141c;
        }
    }
}
</style>
